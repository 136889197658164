<template>
  <Transition v-if="showBanner" name="fade" appear>
    <div class="modal-overlay" v-show="modalStore.showModal">
      <div class="modal-content" @click.stop>
        <div class="container d-flex flex-lg-row flex-column" style="height: 100dvh">
          <div id="nick-logo-container" class="d-flex flex-column">
            <Transition name="slide-left" appear>
              <img src="https://petalo.mints.cloud/public-assets/logo-nick" id="nick-logo" alt="Nickelodeon Logo" class="img-fluid align-self-center align-lg-self-start" v-show="modalStore.imageShow" />
            </Transition>
          </div>
          <div id="modal-container" class="d-flex flex-lg-row-reverse flex-column w-100 h-100">
            <div id="petalo-container" class="d-flex flex-column align-items-center justify-content-center">
              <Transition name="slide-bottom" appear>
                <img src="https://petalo.mints.cloud/public-assets/logo-promo" id="promo-logo" alt="Promo Logo" class="img-fluid promo-logo" v-show="modalStore.imageShow" />
              </Transition>
              <Transition name="slide-right" appear>
                <a class="btn rounded-pill cta-button" id="start-button" @click="discover" v-show="modalStore.imageShow">¡DESCUBRE COMO PARTICIPAR!</a>
              </Transition>
            </div>
            <div id="bob-container" class="d-flex flex-column justify-content-end">
              <Transition name="slide-top" appear>
                <img src="https://petalo.mints.cloud/public-assets/bob-header-left" id="bob-esponja" alt="Bob Header Left" v-show="modalStore.imageShow" />
              </Transition>
            </div>
          </div>
        </div>
      </div>
      <div class="fake-footer">
        <img src="https://petalo.mints.cloud/public-assets/footer-left" id="rocky" alt="Footer Left" class="img-fluid" />
        <img src="https://petalo.mints.cloud/public-assets/footer-right" id="coral" alt="Footer Right" class="img-fluid" />
        <div class="footer-background"></div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { useModalStore } from '@stores/modalStore.js';

export default {
  name: "WelcomeModal",
  data() {
    return {
      showBanner: {type: Boolean, default: true}
    }
  },
  setup() {
    const modalStore = useModalStore();

    const discover = () => {
      modalStore.toggleImage(false);
      const element = document.getElementById('mecanica');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      modalStore.closeModal();  // Close the modal after scrolling
    };

    return { modalStore, discover };
  },
  mounted() {
    this.modalStore.openModal();  
    this.showBanner = !window.location.href.includes('#');
  }
};
</script>

<style scoped>

/* GENERAL MODAL */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(/public-assets/back-site) left top !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.modal-content {
  width: 100%;
  height: 100%;
}

/* GENERAL MODAL */

/* GENERAL MODAL */

#bob-container {
  position: relative;
  width: 45%;
}

#petalo-container {
  position: relative;
  width: 55%;
  z-index: 10;
  right: 20px;
  top: -50px;
}

@media screen and (min-width: 992px) {
  #petalo-container {
    transform: scale(1.1);
  }
}
#bob-esponja {
  height: calc(90dvh - 126px);
  overflow: visible;
  position: relative;
}

#rocky {
  position: absolute;
  bottom: -10px;
  left: 40px;
  max-height: 280px;
}

#coral {
  position: absolute;
  right: 30px;
  max-height: 300px;
  bottom: 10px;
  z-index: -1000;
}

.fake-footer {
  width: 100dvw;
  height: 60px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.footer-background {
  width: 100%;
  height: 275px;
  background-image: url(https://petalo.mints.cloud/public-assets/back-footer);
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: 110% 100% !important;
  position: relative;
}

#nick-logo {
  height: 150px;
}

#nick-logo-container {
  position: absolute;
  margin-top: 1em;
}

#promo-logo {
  max-width: 100%;
  height: auto;
  position: relative;
}

.cta-button {
  background-color: #FF5300;
  color: white;
  font-size: 24pt;
  border: none;
  cursor: pointer;
  width: fit-content;
  font-weight: 500;
  font-family: 'Passion One';
  max-width: 100%;
  padding-inline: 0.8em;
}

.cta-button:hover {
  color:  #FF5300;
  background-color: white;
}

/* Animations */
@keyframes animatedBackground {
  0% { background-position: 0 0; }
  100% { background-position: -30000px 0; }
}

.footer-background {
  animation: animatedBackground 2000s linear infinite;
}

@media screen and (max-width: 1100px) {
  #rocky {
    left: 10px;
  }

  #coral {
    right: 10px;
    max-height: 240px;
  }
}

@media screen and (max-width: 992px) {
  #rocky {
    bottom: 30px;
    max-height: 200px;
  }
  #coral {
    bottom: 45px;
  }
}

@media screen and (max-width: 992px) {

  .cta-button {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: none;
    border-left: none;
    border-radius: 0!important;
    justify-content: center;
    text-align: center;
    padding: 0.5em;
    font-size: 1.2em;
    top: auto;
    width: 100%;
    z-index: 9999;
  }

  #modal-container {
    margin-top: -50px;
  }

  #bob-container {
    position: static;
    width: 100%
  }


  #bob-esponja {
    height: 100%;
  }

  #petalo-container {
    position: static;
    width: 100%;
  }

  #nick-logo {
    height: 110px;
    position: relative;
    left: -60px;
  }

  #nick-logo-container {
    position: relative;
  }

  #promo-logo {
    height: 50dvh;
    position: relative;
    object-fit: contain;
  }

  .footer-background {
    display: none;
  }

}

@media screen and (max-width: 768px) {
  #rocky {
    max-height: 130px;
  }

  #coral {
    max-height: 150px;
  }
}

@media screen and (max-width: 576px) {
  #bob-container, #petalo-container {
    height: 50%
  }

}

/* Transiciones */
/* Fade transition */


.fade-enter-from {

}
.fade-enter-active {

}
.fade-enter-to {

}
.fade-leave-from {

}
.fade-leave-active {

}
.fade-leave-to {

}
.fade-enter-from {
  opacity: 0; /* Starting with opacity 0 (invisible) */
}
.fade-enter-active {
  transition: opacity 0.5s ease-out, transform 0.5s ease-in; /* Transition duration */

}
.fade-enter-to {
  opacity: 1; /* Ending with opacity 1 (visible) */
  transform: translateY(0%);
}

/* Leaving the DOM */
.fade-leave-from {
  opacity: 1; /* Starting with opacity 1 (visible) */
  transform: translateY(0%);
}
.fade-leave-active {
  transition: opacity 0.5s, transform 0.5s ease-in-out; /* Transition duration */

}
.fade-leave-to {
  opacity: 0; /* Ending with opacity 0 (invisible) */
  transform: translateY(-40%);
}




/* Entering the DOM (Slide In from the left) */
.slide-left-enter-from {
  transform: translateX(-100%); /* Start off-screen to the left */
}
.slide-left-enter-active {
  transition: transform 0.5s ease; /* Smooth transition */
}
.slide-left-enter-to {
  transform: translateX(0); /* End at the original position */
}

/* Leaving the DOM (Slide Out to the left) */
.slide-left-leave-from {
  transform: translateX(0); /* Start from the original position */
}
.slide-left-leave-active {
  transition: transform 0.5s ease; /* Smooth transition */
}
.slide-left-leave-to {
  transform: translateX(-100%); /* Move off-screen to the left */
}


/* Entering the DOM (Slide In from the left) */
.slide-right-enter-from {
  transform: translateX(100%); /* Start off-screen to the left */
}
.slide-right-enter-active {
  transition: transform 0.5s ease; /* Smooth transition */
}
.slide-right-enter-to {
  transform: translateX(0); /* End at the original position */
}

/* Leaving the DOM (Slide Out to the left) */
.slide-right-leave-from {
  transform: translateX(0); /* Start from the original position */
}
.slide-right-leave-active {
  transition: transform 0.5s ease; /* Smooth transition */
}
.slide-right-leave-to {
  transform: translateX(200%); /* Move off-screen to the left */
}


/* Entering the DOM (Slide In from the left with no bounce) */
.slide-top-enter-from {
  transform: translateY(-100%); /* Start off-screen to the left */
}
.slide-top-enter-active {
  transition: transform 0.5s ease; /* Smooth transition for sliding in */
}
.slide-top-enter-to {
  transform: translateY(0); /* End at the original position */
}

/* Leaving the DOM (Slide Out to the left with a bounce effect) */
.slide-top-leave-from {
  transform: translateY(0); /* Start at the original position */
}
.slide-top-leave-active {
  /*animation: translateY(-100%) 0.6s ease-in;  Apply the bounceOut animation */
  transition: transform 0.5s linear
}
.slide-top-leave-to {
  transform: translateY(-200%); /* End off-screen to the left */
}



/* Entering the DOM (Slide In from the left with no bounce) */
.slide-bottom-enter-from {
  transform: translateY(100%); /* Start off-screen to the left */
}
.slide-bottom-enter-active {
  transition: transform 0.5s ease; /* Smooth transition for sliding in */
}
.slide-bottom-enter-to {
  transform: translateY(0); /* End at the original position */
}

/* Leaving the DOM (Slide Out to the left with a bounce effect) */
.slide-bottom-leave-from {
  transform: translateY(0); /* Start at the original position */
}
.slide-bottom-leave-active {
  /*animation: translateY(-100%) 0.6s ease-in;  Apply the bounceOut animation */
  transition: transform 0.5s ease
}
.slide-bottom-leave-to {
  transform: translateY(200%); /* End off-screen to the left */
}


</style>
